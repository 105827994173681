<template>
  <div class="page-wrapper theme-green-forest">
    <Header />
    <section class="search-section">
      <ul class="section__items section__items--wrap">
        <a-spin v-if="isLoading" />
        <template v-else-if="data.length">
          <SectionCardSmall
            v-for="item in data"
            :key="item.id"
            :content="item"
          />
        </template>
        <span class="section__item-type-wrapper" v-else>No data</span>
      </ul>
    </section>
    <Footer />
    <subscription-modal @click="onDone" />
  </div>
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import { searchByTag } from '@/services/other.service'
import SectionCardSmall from '../components/cards/SectionCardSmall.vue'
import SubscriptionModal from '../components/subscribe/SubscriptionModal.vue'
import { mapMutations } from 'vuex'
export default {
  components: { Header, Footer, SectionCardSmall, SubscriptionModal },
  data() {
    return {
      data: [],
      isLoading: false,
    }
  },
  watch: {
    '$route.query': {
      handler() {
        this.fetchData()
      },
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapMutations('subscription', ['SET_OPEN']),
    fetchData() {
      if (!this.$route.query.s && !this.$route.query.tags) return
      this.isLoading = true
      searchByTag(this.$route.query)
        .then((res) => {
          this.data = res.data
          this.isLoading = false
        })
        .catch((err) => {
          //   notification.error({ message: err.message })
          this.isLoading = false
        })
    },
    onDone() {
      this.SET_OPEN({ isOpen: false })
      window.location.reload()
    },
  },
}
</script>

<style lang="scss" scoped>
.search-section {
  margin-top: 62px;
}
</style>
